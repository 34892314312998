import React from 'react';

const ReviewsSection = () => {
  return (
    <section
      id="reviews-section"
      className="text-center 
      mt-8 xs:mt-10 md:mt-12 lg:mt-16 
      px-3 xs:px-4 md:px-6 lg:px-8"
    >
      <div>
        {/* Title Image */}
        <img
          src="assets/customerreviews.png"
          alt="Customer Reviews"
          className="title-image 
            w-[280px] xs:w-[320px] md:w-[400px] lg:w-[450px] xl:w-[500px] 
            h-auto mb-4 xs:mb-6 md:mb-8 lg:mb-10 
            mx-auto"
        />

        {/* Rating Image */}
        <div className="rating-image mb-4 xs:mb-6 md:mb-8 lg:mb-10">
          <img
            src="assets/reviews.png"
            alt="Rating"
            className="mx-auto 
              w-[300px] xs:w-[400px] md:w-[500px] lg:w-[600px] xl:w-[700px] 
              h-auto"
          />
        </div>

        {/* Customer Review Images */}
        <div
          className="reviewimages 
          flex flex-col xs:flex-row 
          items-center xs:justify-center 
          gap-4 xs:gap-6 md:gap-8 lg:gap-10"
        >
          <img
            src="assets/1.png"
            alt="Customer Review 1"
            className="customerreviewimages 
              w-[280px] xs:w-[220px] md:w-[280px] lg:w-[320px] xl:w-[350px] 
              h-auto"
          />
          <img
            src="assets/2.png"
            alt="Customer Review 2"
            className="customerreviewimages 
              w-[280px] xs:w-[220px] md:w-[280px] lg:w-[320px] xl:w-[350px] 
              h-auto"
          />
          <img
            src="assets/3.png"
            alt="Customer Review 3"
            className="customerreviewimages 
              w-[280px] xs:w-[220px] md:w-[280px] lg:w-[320px] xl:w-[350px] 
              h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;
