import React from 'react';

const WinRealPrizesSection = () => {
  return (
    <section
      id="win-real-prizes"
      className="winrealprizes relative 
        flex justify-center items-center 
        h-[40vh] xs:h-[50vh] md:h-[75vh] lg:h-[100vh] 
        bg-no-repeat bg-center bg-contain md:bg-cover 
        mb-0"
      style={{
        backgroundImage: `url(assets/${
          window.innerWidth >= 768
            ? 'realprizesbg.png'
            : 'winrealprizesmob.png'
        })`,
      }}
    >
      <div className="absolute inset-0 hidden md:block"></div>
    </section>
  );
};

export default WinRealPrizesSection;
