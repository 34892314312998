import React from 'react';

const DownloadSection = () => {
  return (
    <section className="download-section py-8 sm:py-12 lg:py-16 px-4 sm:px-5 min-h-[450px] sm:min-h-[500px] lg:min-h-[600px] relative z-[1]">
      {/* Background Image */}
      <img
        src="assets/blue background_1.png"
        alt="Background"
        className="download-section-img absolute top-0 left-0 w-full h-full object-cover z-[-1]"
      />

      {/* Container Image */}
      <img
        src="assets/download_1.png"
        alt="container"
        className="download-section-container 
    w-[95%] sm:w-[90%] lg:w-4/5 xl:w-3/4 max-w-[955px] 
    mx-auto absolute top-1% sm:top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
    z-10 
    h-[330px] sm:h-[320px] md:h-[330px] lg:h-[350px] xl:h-[400px] 2xl:h-[335px]"
      />

      {/* Content inside the container */}
      <div className="download-section-content absolute top-[0%] sm:top-[8%] lg:top-[4%] xl:top-[2%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col sm:flex-row items-center w-full sm:w-auto sm:space-x-8 z-20 text-white">
        {/* Logo */}
        <img
          src="assets/download_5.png"
          alt="Logo"
          className="download-section-logo w-[100px] sm:w-[125px] lg:w-[145px] xl:w-[160px] relative sm:absolute sm:top-[-45px] sm:left-[50px] mb-4 sm:mb-0"
        />

        {/* Right-aligned Content */}
        <div className="text-center w-full sm:w-auto px-4 sm:px-0">
          {/* Download Button */}
          <a
            href="/assets/claw_king.apk"
            download
            className="download-section-button inline-block w-[90%] sm:w-[300px] lg:w-[400px] xl:w-[450px] text-white py-3 sm:py-4 px-4 sm:px-8 text-2xl sm:text-lg lg:text-2xl xl:text-3xl rounded-lg relative sm:absolute sm:top-[-30px] sm:left-[235px]"
          >
            <img
              src="/assets/download_button.png"
              alt="Download"
              className="w-full h-auto rounded-lg"
            />
            <span className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-white text-2xl sm:text-lg lg:text-2xl xl:text-3xl">
              <strong>Download</strong>
            </span>
          </a>


          {/* Paragraph Text */}
          <p
            className="download-section-p text-white text-sm sm:text-base lg:text-lg xl:text-xl 
                   mt-4 sm:mt-0 
                   relative 
                   top-0 sm:top-[30px] lg:top-[40px] xl:top-[50px] 
                   sm:left-[185px] 
                   w-[100%] sm:w-[70%] lg:w-[55%] xl:w-[65%] 
                   mx-auto sm:ml-[50px] sm:mr-0
                   max-w-[950px]
                   leading-tight"
          >
            Experience the ultimate claw machine game—play real claw machines
            anytime, anywhere, and win incredible rewards delivered straight to
            you!
          </p>
        </div>
      </div>
    </section>

  );
};

export default DownloadSection;
