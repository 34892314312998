import React from 'react';

const HowToPlaySection = () => {
  return (
    <section
  className="how-to-play-container 
  flex justify-center items-center 
  max-w-[1200px] mx-auto 
  px-3 xs:px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12
  py-6 xs:py-8 md:py-10 lg:py-12 xl:py-16"
>
  <div
    className="how-to-play-content 
    w-full flex flex-col md:flex-row 
    items-center md:justify-between 
    gap-6 xs:gap-8 md:gap-10 lg:gap-12 xl:gap-16"
  >
    {/* How to Play Image */}
    <div className="how-to-play-image-container flex flex-col items-center lg:items-start mb-4 lg:mb-0">
      <img
        src="/assets/howtoplay.png"
        alt="How to Play Claw King"
        className="h-auto w-full xs:w-[50%] md:w-[200%] lg:w-[200%] xl:w-[200%] 2xl:w-[180%]"
      />
    </div>

    {/* Video Container */}
    <div
      className="video-container 
      w-full md:w-[45%] lg:w-[100%] xl:w-[90%] 2xl:w-[80%]
      relative mt-[-2rem] xs:mt-[-3rem] md:mt-[-4rem] lg:mt-[-1rem] xl:mt-[-1rem]"
    >
      <video
        onError={(e) => {
          const error = e.target.error;
          if (error) {
            console.error('Video Error:', error);
          } else {
            console.error('Video Error: An unknown error occurred.');
          }
        }}
        className="w-[85%] xs:w-[80%] md:w-full 
        max-w-[720px] h-auto 
        mx-auto md:ml-[10rem] md:mt-[-7.5rem] xl:ml-[12rem] 2xl:ml-[14rem]"
        autoPlay
        muted
        loop
        playsInline
         >
        <source src="/assets/tmvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</section>

  );
};

export default HowToPlaySection;
