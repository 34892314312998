import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import faqTitle from '../assets/faqtitle.png';

const FAQSection = () => {
  const [showExtraQuestions, setShowExtraQuestions] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleExtraQuestions = () => {
    setShowExtraQuestions(!showExtraQuestions);
  };

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const renderQuestion = (question, index) => {
    const isActive = activeQuestion === index;

    return (
      <div
        key={index}
        id="FAQSection"
        className={`question rounded-md p-2 xs:p-3 sm:p-4 relative flex items-center font-kid-games cursor-pointer transition-all duration-300 mb-4 ${isActive ? 'bg-white text-[#010226]' : 'bg-opacity-10 bg-white text-white'
          }`}
        onClick={() => toggleQuestion(index)}
      >
        <span
          className={`number absolute left-[-30px] top-0 font-bold text-lg text-white`}
        >
          {index + 1}
        </span>
        <div className="dropdown flex flex-col w-full">
          <div
            className={`question-text text-sm sm:text-base lg:text-lg ${isActive ? 'text-[#010226] font-bold' : 'text-white'
              }`}
          >
            {question.title}
          </div>
          <div
            className={`arrow absolute right-2 top-0 sm:top-2 sm:right-2 w-2 h-2 sm:w-5 sm:h-5 bg-contain bg-center bg-no-repeat transition-transform duration-300 ease-out ${isActive ? 'rotate-180' : ''
              }`}
            style={{
              backgroundImage: "url('assets/down arrow.png')",
              filter: isActive ? 'invert(1)' : 'invert(0)',
            }}
          ></div>
          {isActive && (
            <div className="answer mt-2 p-2 bg-white text-[#1e1f5f] rounded-md text-xs sm:text-sm lg:text-base transition-all duration-300 ease-in-out opacity-100">
              {question.answer}
            </div>
          )}
        </div>
      </div>
    );
  };

  const questions = [
    {
      title: 'I changed my device, how can I access my former account?',
      answer:
        "Don't worry! Once you have registered an account with your phone number, you can sign in from any device. Use the 'Logout' option in the profile page to sign out and follow the instructions to log back in.",
    },
    {
      title: 'My phone number is already registered?',
      answer: 'Only a single phone number can be linked to only one Claw King account.',
    },
    {
      title: 'I did not receive an OTP during registration',
      answer:
        'Make sure you enter your phone number correctly and try again after sometime when "Resend OTP" option is available',
    },
    {
      title: 'Can I buy the prizes directly? Or is there a place I can buy the prizes I see on Claw King?',
      answer:
        'No, the prizes cannot be bought directly on the app or from the company. As the prizes cannot be legally sold through Claw King, please keep in mind that any sales listing found for prizes available on Claw King are not done through any license and are not eligible for any kind of warranty. Also note that counterfeits are sometimes found on such re-selling websites that may present unknown hazards as they may not be certified either nor be made under the same manufacturing quality.In order to combat fraud, we may at times investigate accounts under suspicion of illegal activities such as prize scalping and/or reselling at any time and without warning. Any account discovered engaging in activities will be immediately restricted and their user access to Claw King may be blocked indefinitely and without warning. This includes, but is not limited to, delivery address being blacklisted from our services and any/all pending prizes being revoked. Please be aware that such measures are irreversible and cannot be appealed.',
    },
  ];

  const extraQuestions = [
    {
      title:
        'Can I buy the prizes directly? Or is there a place I can buy the prizes I see on Claw King?',
      answer:
        'No, the prizes cannot be bought directly on the app or from the company As the prizes cannot be legally sold through Claw King, please keep in mind that any sales listing found for prizes available on Claw King are not done through any license and are not eligible for any kind of warranty. Also note that counterfeits are sometimes found on such re-selling websites that may present unknown hazards as they may not be certified either nor be made under the same manufacturing quality.In order to combat fraud, we may at times investigate accounts under suspicion of illegal activities such as prize scalping and/or reselling at any time and without warning. Any account discovered engaging in activities will be immediately restricted and their user access to Claw King may be blocked indefinitely and without warning. This includes, but is not limited to, delivery address being blacklisted from our services and any/all pending prizes being revoked. Please be aware that such measures are irreversible and cannot be appealed.',
    },
    {
      title: 'I won a prize! How can I get it shipped to me?',
      answer:
        'Congrats on your win! Great catch! Before you can start getting your prize shipped to you, remember to register your physical home address on your Profile Page. Once your physical address has been successfully registered with us then you can request the prizes currently stored in your cart to be shipped to you via the Shipping page. Once the shipping request has been received then an order will be automatically made. Order preparations typically take between 7-14 business days, however, this may vary depending on various circumstances. After your order has been dispatched, you will receive an automated email with the necessary information to track your package until it is received by you. Please keep in mind that we make no guarantees as to the length of time it may take for an order to be shipped. However, please rest assured that all your orders will be shipped as soon as possible. Kindly note that contacting us will not enable us to speed up the order shipping process on an individual basis and we reserve the right to not reply to order status inquiries unless in extenuating circumstances of an order being pending for a significant period of time.',
    },
    {
      title: 'How to send the prizes to other users?',
      answer:
        'Won prizes are linked to your account and under no circumstances the shipping cannot be transferred to another person or account. However you can add a secondary delivery address to send the prize to another person',
    },
    {
      title: 'Are all the prizes inside the machine real?',
      answer:
        'While we do use real prizes in our machines, some of our claw machines also incorporate dummy plushies/boxes to ensure an enjoyable and fair experience for all players.These dummy plushies/boxes are introduced because certain actual prizes may be difficult to grab due to their size, weight, or position within the machine. This way, everyone has a chance to win and enjoy the game.Rest assured that winning players will receive a real prize corresponding to the machines thumbnail.',
    },

    {
      title: "Is there a phone number or hotline where I can contact the support team?",
      answer: "Unfortunately, we cannot offer any kind of support via telephone. However, you can send your query to our email id:support@clawking.in. You can always raise a dispute in the app if it's regarding a game session in the 'Gameplay History' section of the app on the profile page. We reserve the right to respond to emails and the speed at which we respond."
    },
    
    {
      title: "Can I exchange my prizes?",
      answer: "You can always exchange your prizes to earn in-game currency but you cannot exchange it for other rewards."
    },

    {
      title: "How long will it take my prize to arrive?",
      answer: "Once shipped, prizes usually take upto 7-10 days to reach you. This may vary based on location and details provided by you during the time of shipping."
    },

    {
      title: "Can I return my prize?",
      answer: "Unfortunately we do not accept any returns or exchanges on these gifts"
    },

    {
      title: "My prize was damaged when it arrived. What can I do?",
      answer: "Please raise a request in the 'Contact Us' section of the profile page. Our support team will verify the damage and will resend you a new prize. Make sure to raise it on the day of delivery."
    },

    {
      title: "How do I deposit Money?",
      answer: "You can deposit money on Claw King using any of the Modes present on the deposit page. The modes include , UPI, Paytm , Amazon Pay , Credit Cards , Debit Cards & Net-banking."
    },

    {
      title: "Money from the account is debited and not credited to my account",
      answer: "There may have been a technical issue at your bank end due to which this happened. If we receive the money we will credit it to your Claw King wallet. If we do not receive the money the same will be refunded to your bank account by your bank in 7-10 working days."
    },

    {
      title: "How do I get a Refund?",
      answer: "We do not refund the users unless there is a technical issue on our end, Please read about it in our T&C"
    },

    {
      title: "Is it safe to provide card details on the platform?",
      answer: "We are certified by Master and Visa as a secure platform, we do not save any cards on our database . "
    },

  ];

  return (
    <div
      id="faqs"
      className="py-12 xs:py-16 sm:py-20 lg:py-32 px-3 xs:px-4 sm:px-8 lg:px-16 bg-cover bg-no-repeat bg-top min-h-[350px] xs:min-h-[400px] sm:min-h-[500px] lg:min-h-[600px] flex flex-col items-center justify-center mt-6 xs:mt-8 sm:mt-12 transition-all duration-500 ease-in-out bg-white"
      style={{
        backgroundImage: "url('assets/blue background_3.png')",
      }}
    >
      <img
        src={faqTitle}
        alt="FAQ"
        className="w-[150px] xs:w-[200px] sm:w-[250px] lg:w-[300px] mb-4 xs:mb-6 sm:mb-8 mt-6 xs:mt-8 sm:mt-12"
      />

      {/* Question Container */}
      <div className="w-full max-w-[98%] xs:max-w-[95%] sm:max-w-[90%] lg:max-w-[80%] space-y-3 xs:space-y-4">
        {questions.map((question, index) => renderQuestion(question, index))}

        {/* Extra questions */}
        <div
          className={`extra-questions transition-all duration-500 ease-in-out ${showExtraQuestions ? 'block' : 'hidden'
            }`}
        >
          {showExtraQuestions &&
            extraQuestions.map((question, index) =>
              renderQuestion(question, index + questions.length)
            )}
        </div>
      </div>

      {/* View More Button */}
      <button
        id="view-more-btn"
        className="text-center mt-4 xs:mt-6 sm:mt-8 px-3 xs:px-4 sm:px-6 py-2 sm:py-3 bg-[#007BFF] text-white font-bold rounded-md text-xs xs:text-sm sm:text-base hover:bg-[#0056b3] transition-colors duration-300 ease-in-out"
        onClick={toggleExtraQuestions}
      >
        {showExtraQuestions ? 'View Less' : 'View More'}
      </button>
    </div>
  );
};

export default FAQSection;
