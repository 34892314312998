import React from 'react';

const FooterSection = () => {
  return (
    <footer
      className="bg-cover bg-center bg-no-repeat text-white text-center py-6 xs:py-8 md:py-10 lg:py-12 
        min-h-[100px] xs:min-h-[150px] md:min-h-[175px] lg:min-h-[200px] 
        px-3 xs:px-4 md:px-6 lg:px-8 mt-0 pt-0"
      style={{ backgroundImage: "url('assets/gray background.png')" }}
    >
      <div
        className="flex flex-col md:flex-row justify-center items-center 
        gap-4 xs:gap-6 md:gap-8 lg:gap-10 max-w-6xl mx-auto"
      >
        <div className="flex flex-col items-center md:items-end">
          <img
            src="assets/logo.png"
            alt="Your Logo"
            className="w-[60px] xs:w-[80px] md:w-[90px] lg:w-[100px] h-auto"
          />
          <div className="flex justify-center items-center mt-2 xs:mt-3 gap-3 xs:gap-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <img
                src="assets/facebook.png"
                alt="Facebook"
                className="w-[16px] xs:w-[18px] md:w-[20px] h-[18px] xs:h-[20px] md:h-[22px]"
              />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <img
                src="assets/insta.png"
                alt="Instagram"
                className="w-[16px] xs:w-[18px] md:w-[20px] h-[18px] xs:h-[20px] md:h-[22px]"
              />
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <img
                src="assets/youtube.png"
                alt="YouTube"
                className="w-[16px] xs:w-[18px] md:w-[20px] h-[18px] xs:h-[20px] md:h-[22px]"
              />
            </a>
          </div>
        </div>
        <nav className="flex flex-col items-center md:items-start gap-1.5 xs:gap-2 md:gap-3">
          <a
            href="/"
            className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
          >
            Home
          </a>
          <a
            href="/contact"
            className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
          >
            Contact Us
          </a>
          <a
            href="/contact"
            className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
          >
            About Us
          </a>
        </nav>
      </div>
      <p className="mt-4 xs:mt-6 md:mt-8 text-[11px] xs:text-xs md:text-sm lg:text-base text-gray-400">
        © 2024 Lucratech Pvt. Ltd. All rights reserved.
      </p>
    </footer>
  );
};

export default FooterSection;
