import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  // const navigate = useNavigate(); // Added navigate hook

  const isTermsPage = location.pathname === '/terms';

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  // const handleContactClick = (e) => {
  //   e.preventDefault();
  //   // Scroll to the contact section
  //   const contactSection = document.getElementById("contact-us-section");
  //   if (contactSection) {
  //     contactSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // const handleContactClick2 = (e) => {
  //   e.preventDefault();
  //   // Scroll to the contact section
  //   const contactSection = document.getElementById("FAQSection");
  //   if (contactSection) {
  //     contactSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const closeMenuOnOverlayClick = () => {
    if (isMenuOpen) toggleMenu();
  };

  return (
    <header className="relative overflow-hidden h-[85vh] xs:h-[90vh] text-purple-700">
      <div className="relative z-20 flex items-center justify-between px-4 xs:px-6 md:px-8 lg:px-10 py-4 xs:py-5 md:py-6 lg:py-7">
        <div className="nav-logo">
          <Link to="/">
            <img
              src="assets/download_5.png"
              alt="Logo"
              className="max-h-[45px] xs:max-h-[55px] md:max-h-[65px] lg:max-h-[75px] w-auto mt-[-10px] xs:mt-[-12px] md:mt-[-15px]"
            />
          </Link>
        </div>

        {/* Hamburger Icon */}
        <button
          className="block lg:hidden z-[10000] relative group"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <div className="relative flex overflow-hidden items-center justify-center w-[30px] h-[30px]">
            <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center">
              {/* Top line */}
              <div
                className={`bg-white h-[2px] w-[20px] transform transition-all duration-300 origin-left ${isMenuOpen ? 'rotate-[42deg] translate-y-[5px]' : ''
                  }`}
              />
              {/* Middle line */}
              <div
                className={`bg-white h-[2px] w-[20px] rounded transform transition-all duration-300 ${isMenuOpen ? 'opacity-0' : ''
                  }`}
              />
              {/* Bottom line */}
              <div
                className={`bg-white h-[2px] w-[20px] transform transition-all duration-300 origin-left ${isMenuOpen ? '-rotate-[42deg] translate-y-[-5px]' : ''
                  }`}
              />
            </div>
          </div>
        </button>

        <nav id="desktop-nav" className="hidden lg:flex space-x-6 md:space-x-8">
          <Link to="/" className="text-white text-base md:text-lg">
            Home
          </Link>
          <Link to="/terms" className="text-white text-base md:text-lg">
            Privacy Policy
          </Link>
          <Link to="/terms" className="text-white text-base md:text-lg">
            Terms of Services
          </Link>
          <Link to="/faq" className="text-white text-base md:text-lg">
            Faq
          </Link>
          <Link to="/contact" className="text-white text-base md:text-lg">
            Contact Us
          </Link>
        </nav>
      </div>

      <video
        id="header-video"
        className={`absolute top-0 left-0 w-full h-full object-cover z-[-1] hidden lg:block ${isTermsPage ? 'hidden' : 'block'}`}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src="/assets/background1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video
        id="mobile-video"
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1] block lg:hidden"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src="/assets/Clawvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Mobile Navigation Menu */}
      <nav
        className={`fixed top-0 right-0 bottom-0 w-[300px] bg-[rgb(79_179_255_/_70%)] backdrop-blur-sm z-[9999]
          pt-20 px-6 transition-transform duration-300 ease-in-out transform
          ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
          lg:hidden`}
      >
        <div className="flex flex-col space-y-6">
          <Link
            to="/"
            onClick={toggleMenu}
            className="text-white text-lg"
          >
            Home
          </Link>
          <Link
            to="/terms"
            onClick={toggleMenu}
            className="text-white text-lg"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms"
            onClick={toggleMenu}
            className="text-white text-lg"
          >
            Terms of Services
          </Link>
          <Link
            to="/faq"
            onClick={toggleMenu}
            className="text-white text-lg"
          >
            FAQ
          </Link>
          <Link
            to="/contact"
            onClick={toggleMenu}
            className="text-white text-lg"
          >
            Contact us
          </Link>
        </div>
      </nav>

      {/* Overlay to Close Menu */}
      <div
        className={`fixed inset-0 bg-black/50 z-[9998] lg:hidden transition-opacity duration-300
          ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
        onClick={closeMenuOnOverlayClick}
      />
    </header>
  );
};

export default Header;
