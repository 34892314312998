import React, { useRef } from 'react';

const HowToInstallSection = () => {
  const carouselRef = useRef();

  const scrollCarousel = (direction) => {
    const container = carouselRef.current;

    if (direction === 'left') {
      container.style.transition = 'transform 0.5s ease-in-out';
      container.style.transform = 'translateX(33.33%)';

      setTimeout(() => {
        container.style.transition = 'none';
        const lastItem = container.lastElementChild;
        container.prepend(lastItem);
        container.style.transform = 'translateX(0)';
      }, 500);
    } else if (direction === 'right') {
      container.style.transition = 'transform 0.5s ease-in-out';
      container.style.transform = 'translateX(-33.33%)';

      setTimeout(() => {
        container.style.transition = 'none';
        const firstItem = container.firstElementChild;
        container.append(firstItem);
        container.style.transform = 'translateX(0)';
      }, 500);
    }
  };

  return (
    <section
      className="py-8 xs:py-12 md:py-16 lg:py-20 px-3 xs:px-4 md:px-5 lg:px-6 
      min-h-[450px] xs:min-h-[500px] md:min-h-[550px] lg:min-h-[600px] mt-6 xs:mt-8 md:mt-10 relative"
    >
      {/* Background Image */}
      <img
        src="assets/uk.png"
        alt="howtoinstall_background_image"
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
      />
      {/* Title Image */}
      <img
        src="assets/Howtoinstall.png"
        className="absolute 
          w-[280px] xs:w-[320px] md:w-[380px] lg:w-[400px] 
          top-[-180px] xs:top-[-180px] md:top-[-50px] lg:top-[-225px] 
          left-1/2 transform -translate-x-1/2 h-auto z-20"
        alt="Installation Instructions"
      />

      {/* Carousel Container */}
      <div
        className="relative flex justify-center items-center z-10  
        w-[95%] xs:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 
        mx-auto mt-[-150px] xs:mt-[-170px] md:mt-[-180px] lg:mt-[-200px]"
      >
        <div className="w-full overflow-hidden">
          <div
            ref={carouselRef}
            className="carousel-items flex transition-all duration-500 ease-in-out"
          >
            {/* Carousel Items */}
            <div className="flex-shrink-0 w-full md:w-1/3 px-1 xs:px-2 md:px-3">
              <img
                src="assets/01.png"
                className="w-full h-auto"
                alt="Step 1"
              />
              <p className="mt-2 text-center text-sm md:text-base font-inter text-white font-bold">
                Click 'download' button in the popup below to continue.
              </p>
            </div>
            <div className="flex-shrink-0 w-full md:w-1/3 px-1 xs:px-2 md:px-3">
              <img
                src="assets/02.png"
                className="w-full h-auto"
                alt="Step 2"
              />
              <p className="mt-2 text-center text-sm md:text-base font-inter text-white font-bold">
                Check your phone notifications to find the download app.
              </p>
            </div>
            <div className="flex-shrink-0 w-full md:w-1/3 px-1 xs:px-2 md:px-3">
              <img
                src="assets/03.png"
                className="w-full h-auto"
                alt="Step 3"
              />
              <p className="mt-2 text-center text-sm md:text-base font-inter text-white font-bold">
                Locate the download file on your device and open it to begin installation.
              </p>
            </div>
            <div className="flex-shrink-0 w-full md:w-1/3 px-1 xs:px-2 md:px-3">
              <img
                src="assets/04.png"
                className="w-full h-auto"
                alt="Step 4"
              />
              <p className="mt-2 text-center text-sm md:text-base font-inter text-white font-bold">
                Click allow from this source.
              </p>
            </div>
            <div className="flex-shrink-0 w-full md:w-1/3 px-1 xs:px-2 md:px-3">
              <img
                src="assets/05.png"
                className="w-full h-auto"
                alt="Step 5"
              />
              <p className="mt-2 text-center text-sm md:text-base font-inter text-white font-bold">
                Click Open.
              </p>
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <button
          className="absolute left-0 xs:left-[2%] md:left-[3%] lg:left-[-10%] 
            top-1/2 transform -translate-y-1/2 
            text-white text-2xl xs:text-3xl md:text-4xl 
            opacity-10.75 hover:opacity-100 z-10"
          onClick={() => scrollCarousel('left')}
        >
          ❮
        </button>

        <button
          className="absolute right-0 xs:right-[2%] md:right-[3%] lg:right-[-10%] 
            top-1/2 transform -translate-y-1/2 
            text-white text-2xl xs:text-3xl md:text-4xl 
            opacity-10.75 hover:opacity-100 z-10"
          onClick={() => scrollCarousel('right')}
        >
          ❯
        </button>
      </div>
    </section>
  );
};

export default HowToInstallSection;
