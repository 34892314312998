import React from 'react';

const AboutUsAndContactUsSection = () => {
  return (
    <div id='contact-us-section'
      className="bg-cover bg-center text-black py-8 sm:py-12 contact-us-section2 mb-0 pb-0"
      style={{ backgroundColor: 'rgb(255, 222, 7)' }}
    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4 sm:px-6">
        {/* About Us Section */}
        <div
          id="about-us-section"
          className="flex flex-col items-center text-center md:items-end md:text-right p-4 space-y-6"
        >
          <img
            src="assets/aboutus.png"
            alt="About Us"
            className="w-[60%] sm:w-[50%] max-w-[120px] sm:max-w-[150px] transition-transform hover:scale-105"
          />
          <p className="aboutusparagraph text-sm sm:text-base lg:text-lg max-w-prose">
            We at Lucratech are dedicated to revolutionizing entertainment by
            blending digital innovation with real-world excitement. Our mission
            is to create immersive gameplay experiences that seamlessly bridge
            the gap between virtual fun and tangible rewards. By combining
            advanced technology with classic arcade charm, we aim to deliver
            unique and engaging moments that players can cherish, both online
            and offline. At Lucratech, every game is a step towards redefining
            how the digital and real worlds connect.
          </p>
        </div>

        {/* Contact Us Section */}
        <div
          id="contact-us-section"
          className="flex flex-col items-center text-center md:items-start md:text-left p-4 space-y-6"
        >
          <img
            src="assets/contactus.png"
            alt="Contact Us"
            className="w-[70%] sm:w-[60%] max-w-[150px] sm:max-w-[200px] transition-transform hover:scale-105"
          />
          
          <p className="contactusparagraph text-sm sm:text-base lg:text-lg max-w-prose">
            <strong>Lucratech Pvt. Ltd.</strong> Plot No.72, H. No.8-2-334/1, First floor, Road
            No.5, Opp. SBI Executive Enclave, Banjara Hills, Hyderabad-500034
            Telangana, India. Support: +91 8008963337 Mail ID:
            support@clawking.in.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsAndContactUsSection;
