import React from 'react';

const WhyClawKingSection = () => {
  return (
    <section
    id="why-claw-kings"
    className="text-center 
      mt-8 xs:mt-10 md:mt-12 lg:mt-16 
      px-3 xs:px-4 md:px-6 lg:px-8 
      bg-transparent relative z-10"
  >
    {/* Title Image */}
    <img
      src="assets/whyclawking.png"
      alt="Why Claw King"
      className="title-image 
        w-[250px] xs:w-[300px] md:w-[350px] lg:w-[400px] 
        h-auto mb-6 xs:mb-8 md:mb-10 lg:mb-12 
        mx-auto bg-transparent"
    />
  
    {/* Features */}
    <div
      className="features 
        grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 
        gap-2 xs:gap-1 md:gap-2 lg:gap-3 xl:gap-4 
        max-w-[1440px] mx-auto bg-transparent"
    >
      {/* Feature 1 */}
      <div
        className="feature 
          text-center flex flex-col items-center 
          px-2 xs:px-1 md:px-2 lg:px-2 xl:px-2
          bg-transparent"
      >
        <img
          src="assets/100_secure.png"
          alt="100% Secure"
          className="feature-image 
            w-[120px] xs:w-[140px] md:w-[180px] lg:w-[220px] 
            h-auto mb-2 xs:mb-3 bg-transparent"
        />
        <p
          className="feature-text 
            text-sm xs:text-base md:text-lg lg:text-xl 
            text-[rgb(0,115,198)] font-inter font-semibold"
        >
          100% SECURE
        </p>
      </div>
  
      {/* Feature 2 */}
      <div
        className="feature 
          text-center flex flex-col items-center 
          px-2 xs:px-1 md:px-2 lg:px-2 xl:px-2 
          bg-transparent"
      >
        <img
          src="assets/languages.png"
          alt="6 Languages"
          className="feature-image 
            w-[120px] xs:w-[140px] md:w-[180px] lg:w-[220px] 
            h-auto mb-2 xs:mb-3 bg-transparent"
        />
        <p
          className="feature-text 
            text-sm xs:text-base md:text-lg lg:text-xl 
            text-[rgb(0,115,198)] font-inter font-semibold"
        >
          6 LANGUAGES
        </p>
      </div>
  
      {/* Feature 3 */}
      <div
        className="feature 
          text-center flex flex-col items-center 
          px-2 xs:px-1 md:px-2 lg:px-2 xl:px-2 
          xs:col-span-2 lg:col-span-1 
          bg-transparent"
      >
        <img
          src="assets/support.png"
          alt="24/7 Support"
          className="feature-image 
            w-[120px] xs:w-[140px] md:w-[180px] lg:w-[220px] 
            h-auto mb-2 xs:mb-3 bg-transparent"
        />
        <p
          className="feature-text 
            text-sm xs:text-base md:text-lg lg:text-xl 
            text-[rgb(0,115,198)] font-inter font-semibold"
        >
          24/7 SUPPORT
        </p>
      </div>
    </div>
  </section>
  
  
  );
};

export default WhyClawKingSection;
