import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import TermsHeader from './TermsHeader';



const TermsAndConditions = () => {

  const [activeTab, setActiveTab] = useState('terms');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Hamburger menu state


  const tabs = [
    { id: 'terms', label: 'Terms & Conditions' },
    { id: 'privacy', label: 'Privacy Policy' },
    { id: 'cancellation', label: 'Cancellation & Refund' },
    { id: 'delivery', label: 'Shipping & Delivery' },
  ];
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };
  // const closeMenuOnOverlayClick = () => {
  //   if (isMenuOpen) toggleMenu();
  // };
  // const handleContactClick = (e) => {
  //   e.preventDefault();
  //   // Scroll to the contact section
  //   document.getElementById("contact-us-section").scrollIntoView({ behavior: "smooth" });
  // };
  return (
    <div className="min-h-screen flex flex-col">
      
      <main className="flex-grow text-black pt-8 px-4 md:px-10 lg:px-20">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 text-sm md:text-base rounded-t-lg transition-colors ${activeTab === tab.id
                  ? 'bg-orange-500 text-white'
                  : 'bg-gray-300 text-gray-700 hover:bg-orange-400 hover:text-white'
                  }`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <div className="bg-white bg-opacity-90 rounded-lg p-6 md:p-8 shadow-lg">
            {activeTab === 'terms' && (
              <div>
                <h2 className="text-2xl font-bold mb-4">Terms & Conditions</h2>
                <p className="mb-4"><strong>Latest Updated: </strong>November 16th, 2024</p>
                <p className="mb-4">
                  For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean Lucratech Private Limited, whose registered/operational office is Plot no 186, Road no 15, Jubilee Hills, Hyderabad Hyderabad TELANGANA 500033 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
                </p>
                <h3 className="text-2xl font-bold mb-4">Your use of the website and/or purchase from us are governed by following Terms and Conditions:</h3>

                <ul>
                  <li>The content of the pages of this website is subject to change without notice.</li>
                  <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                  <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>
                  <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                  <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                  <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
                  <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
                  <li>You may not create a link to our website from another website or document without Lucratech Private Limited’s prior written consent.</li>
                  <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .</li>
                  <li>We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
                </ul>
                {/* Add more terms content here */}
              </div>
            )}
            {activeTab === 'privacy' && (
              <div>
                <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
                <p className="mb-4"><strong>Latest Updated: </strong>November 16th, 2024</p>
                <p className="mb-4">
                  Claw King (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Claw King.
                </p>
                <p className="mb-4">
                  This Privacy Policy applies to our app, and its associated subdomains (collectively, our “Service”) alongside our application, Claw King. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
                </p>
                <h3 className="text-2xl font-bold mb-4">Definitions and key terms</h3>
                <h4 className="text-2xl font-bold mb-4">To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</h4>
                <ul>
                  <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                  <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Lucratech Private Limited Pvt. Ltd., (Plot no 186, Road no 15, Jubilee Hills, Hyderabad-500033, India), that is responsible for your information under this Privacy Policy.</li>
                  <li>Country: where Claw King or the owners/founders of Claw King are based, in this case is India.</li>
                  <li>Customer: refers to the company, organization or person that signs up to use the Claw King Service to manage the relationships with your consumers or service users.</li>
                  <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Claw King and use the services.</li>
                  <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</li>
                  <li>Personel: refers to those individuals who are employed by Claw King or are under contract to perform a service on behalf of one of the parties.</li>
                  <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
                  <li>Service: refers to the service provided by Claw King as described in the relative terms (if available) and on this platform.</li>
                  <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. </li>
                  <li>App/Application: Claw King app, refers to the SOFTWARE PRODUCT identified above. </li>
                  <li>You: a person or entity that is registered with Claw King to use the Services. </li>
                </ul>
                <h3 className="text-2xl font-bold mb-4">What Information Do We Collect?</h3>
                <h4 className="text-2xl font-bold mb-4">We collect information from you when you visit our app, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form</h4>
                <ul>
                  <li>Game Specific Information</li>
                  <li>Email Address</li>
                  <li>Ad IDs</li>
                  <li>Device ID</li>
                </ul>
                <h3 className="text-2xl font-bold mb-4">When does Claw King use end user information from third parties?</h3>
                <ul>
                  <li>Claw King will collect End User Data necessary to provide the Claw King services to our customers. End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</li>
                </ul>
                <h3 className="text-2xl font-bold mb-4">When does Claw King use customer information from third parties?</h3>
                <ul>
                  <li>We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a Claw King customer, we receive information from a third party that provides automated fraud detection services to Claw King. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</li>
                </ul>
                <h3 className="text-2xl font-bold mb-4">Do we share the information we collect with third parties?</h3>
                <ul>
                  <li>We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.</li>
                  <li>We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and the app, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through the app. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.</li>
                  <li>We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the app in a shared location, and type of the device used to visit the app. They may aggregate information about our advertising and what you see on the app and then provide auditing, research and reporting for us and our advertisers.</li>
                  <li>We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.</li>
                </ul>
                <h3 className="text-2xl font-bold mb-4">Where and when is information collected from customers and end users?</h3>
                <li>Claw King will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.</li>
                <h3 className="text-2xl font-bold mb-4">Where and when is information collected from customers and end users?</h3>
                <li>Claw King will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.</li>
                <h3 className="text-2xl font-bold mb-4">How Do We Use Your Email Address?</h3>
                <li>By submitting your email address on this app, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on
                  sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</li>
                <h3 className="text-2xl font-bold mb-4">How Long Do We Keep Your Information?</h3>
                <li>We keep your information only so long as we need it to provide Claw King to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.</li>
                <h3 className="text-2xl font-bold mb-4">How Do We Protect Your Information?</h3>
                <li>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers,
                  financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Claw King or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</li>
                <h3 className="text-2xl font-bold mb-4">Could my information be transferred to other countries?</h3>
                <li>Claw King is incorporated in India. Information collected via our app, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information.</li>
                <h3 className="text-2xl font-bold mb-4">Is the information collected through the Claw King Service secure?</h3>
                <li>We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information,
                  you agree that intentional misconduct will be the standards used to measure our compliance with that duty.</li>
                <h3 className="text-2xl font-bold mb-4">How Do We Protect Your Information?</h3>
                <li>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file.
                  We cannot, however, ensure or warrant the absolute security of any information you transmit to Claw King or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</li>
                <h3 className="text-2xl font-bold mb-4">How Do We Protect Your Information?</h3>
                <li>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot,
                  however, ensure or warrant the absolute security of any information you transmit to Claw King or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</li>
                <h3 className="text-2xl font-bold mb-4">Can I update or correct my information?</h3>
                <li>The rights you have to request updates or corrections to the information Claw King collects depend on your relationship with Claw King. Personnel may update or correct their information as detailed in our internal company employment policies.</li>
                <li>Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.
                  To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.</li>
                <li>You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.</li>
                <li>If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.</li>
                <h3 className="text-2xl font-bold mb-4">Personnel</h3>
                <li>If you are a Claw King worker or applicant, we collect information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants</li>
                <li>You may contact us in order to (1) update or correct your information, (2) change your preferences with respect to communications and other information you receive from us, or (3) receive a record of the information we have relating to you. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.</li>
                <h3 className="text-2xl font-bold mb-4">Sale of Business</h3>
                <li>We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of Claw King or any of its Corporate Affiliates (as defined herein), or that portion of Claw King or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</li>
                <h3 className="text-2xl font-bold mb-4">Affiliates</h3>
                <li>We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under common control with Claw King, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</li>
                <h3 className="text-2xl font-bold mb-4">Governing Law</h3>
                <li>This Privacy Policy is governed by the laws of India without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.</li>
                <li>The laws of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the app. Your use of the app may also be subject to other local, state, national, or international laws.</li>
                <li>By using Claw King or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our app, or use our services. Continued use of the app, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.</li>
                <h3 className="text-2xl font-bold mb-4">Your Consent</h3>
                <li>We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our Claw King, registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.</li>
                <h3 className="text-2xl font-bold mb-4">Advertising</h3>
                <li>This app may contain third party advertisements and links to third party sites. Claw King does not make any representation as to the accuracy or suitability of any of the information contained in those advertisements or sites and does not accept any responsibility or liability for the conduct or content of those advertisements and sites and the offerings made by the third parties.</li>
                <li>Advertising keeps Claw King and many of the websites and services you use free of charge. We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.</li>
                <li>Third party advertisements and links to other sites where goods or services are advertised are not endorsements or recommendations by Claw King of the third party sites, goods or services. Claw King takes no responsibility for the content of any of the ads, promises made, or the quality/reliability of the products or services offered in all advertisements.</li>
                <h3 className="text-2xl font-bold mb-4">Links to Other Websites</h3>
                <li>This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by Claw King. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</li>
                {/* Add more privacy policy content here */}
              </div>
            )}
            {activeTab === 'cancellation' && (
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  Cancellation & Refund Policy
                </h2>
                <p className="mb-4"><strong>Latest Updated: </strong>November 16th, 2024</p>

                <ul className="list-disc list-inside space-y-2">
                  <li>Cancellations will be considered only if the request is made within same day of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
                  <li>Lucratech Private Limited does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good. .</li>
                  <li>In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within same day of receipt of the products.</li>
                  <li>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within same day of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                  <li>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</li>
                  <li>In case of any Refunds approved by the Lucratech Private Limited, it’ll take 9-15 days for the refund to be processed to the end customer.</li>
                  {/* Add more cancellation policy items here */}
                </ul>
              </div>
            )}
            {activeTab === 'delivery' && (
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  Shipping & Delivery Policy
                </h2>
                <p className="mb-4"><strong>Latest Updated: </strong>November 16th, 2024</p>
                <p className="mb-4">
                  For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only.
                  Orders are shipped within 8-14 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. Lucratech Private Limited is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 8-14 days rom the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 8008963337 or dev@lucratechsol.com.
                </p>
                {/* Add more shipping policy content here */}
              </div>
            )}
          </div>
        </div>

      </main>


      {/* Mobile Navigation Menu */}
      <nav
        className={`fixed top-0 right-0 bottom-0 w-[300px] bg-[rgb(79_179_255_/_70%)] backdrop-blur-sm z-[9999]
  pt-20 px-6 transition-transform duration-300 ease-in-out transform
  ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
  lg:hidden`}

      >
        <div className="flex flex-col space-y-6">
          <Link
            to="/"
            onClick={toggleMenu}
            className="text-white text-lg hover:text-purple-400 transition-colors duration-200"
          >
            Home
          </Link>
          <Link
            to="/terms"
            onClick={toggleMenu}
            className="text-white text-lg hover:text-purple-400 transition-colors duration-200"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms"
            onClick={toggleMenu}
            className="text-white text-lg hover:text-purple-400 transition-colors duration-200"
          >
            Terms of Services
          </Link>
          <Link
            to="/contact"
            onClick={toggleMenu}
            className="text-white text-lg hover:text-purple-400 transition-colors duration-200"
          >
            Contact us
          </Link>
        </div>
      </nav>

      {/* <footer
        className="bg-cover bg-center bg-no-repeat text-white text-center py-6 xs:py-8 md:py-10 lg:py-12 
    min-h-[120px] xs:min-h-[150px] md:min-h-[175px] lg:min-h-[200px] 
    mt-6 xs:mt-8 md:mt-10 lg:mt-0 
    px-3 xs:px-4 md:px-6 lg:px-8"
        style={{ backgroundImage: "url('assets/gray background.png')" }}
      >
        <div
          className="flex flex-col md:flex-row justify-center items-center 
    gap-4 xs:gap-6 md:gap-8 lg:gap-10 max-w-6xl mx-auto"
        >
          <div className="flex flex-col items-center md:items-end">
            <img
              src="/assets/logo.png"
              alt="Your Logo"
              className="w-[60px] xs:w-[80px] md:w-[90px] lg:w-[100px] h-auto"
            />
            <div className="flex justify-center items-center mt-2 xs:mt-3 gap-3 xs:gap-4">
              {['facebook', 'insta', 'youtube'].map((social) => (
                <a
                  key={social}
                  href={`https://www.${social}.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-80 transition-opacity"
                >
                  <img
                    src={`/assets/${social}.png`}
                    alt={social}
                    className="w-[16px] xs:w-[18px] md:w-[20px] h-[18px] xs:h-[20px] md:h-[22px]"
                  />
                </a>
              ))}
            </div>
          </div>
          <nav className="flex flex-col items-center md:items-start gap-1.5 xs:gap-2 md:gap-3">
            <Link
              to="/"
              className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
            >
              Home
            </Link>
            <Link
              to="/contact"
              className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
            >
              Contact Us
            </Link>
            <Link
              to="/about"
              className="text-gray-400 hover:text-gray-200 transition-colors text-xs xs:text-sm md:text-base"
            >
              About Us
            </Link>
          </nav>
        </div>
        <p className="mt-4 xs:mt-6 md:mt-8 text-[11px] xs:text-xs md:text-sm lg:text-base text-gray-400">
          © 2024 Your Company Name. All rights reserved.
        </p>
      </footer> */}

    </div>
  );
};

export default TermsAndConditions;
