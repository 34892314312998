import React from 'react';
import { Link } from 'react-router-dom';

const TermsHeader = ({ isMenuOpen, toggleMenu, closeMenuOnOverlayClick }) => {
  return (
    <header className="relative h-[20vh] md:h-[34vh] overflow-hidden text-white">
      <img
        src="/assets/terms_1.png"
        alt="Header Background"
        className="absolute top-0 left-0 w-full min-h-full object-cover z-[-1]"
      />
      <div className="relative z-10 flex items-center justify-between w-full px-4 py-6 md:px-10 lg:px-20">
        <Link to="/">
          <img
            src="/assets/download_5.png"
            alt="Logo"
            className="max-h-[70px] w-auto mt-[-10px]"
          />
        </Link>
        {/* Hamburger Icon */}
        <button
          className="block lg:hidden z-[10000] relative group"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <div className="relative flex overflow-hidden items-center justify-center w-[30px] h-[30px]">
            <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center">
              {/* Top line */}
              <div
                className={`bg-white h-[2px] w-[20px] transform transition-all duration-300 origin-left ${
                  isMenuOpen ? 'rotate-[42deg] translate-y-[5px]' : ''
                }`}
              />
              {/* Middle line */}
              <div
                className={`bg-white h-[2px] w-[20px] rounded transform transition-all duration-300 ${
                  isMenuOpen ? 'opacity-0' : ''
                }`}
              />
              {/* Bottom line */}
              <div
                className={`bg-white h-[2px] w-[20px] transform transition-all duration-300 origin-left ${
                  isMenuOpen ? '-rotate-[42deg] translate-y-[-5px]' : ''
                }`}
              />
            </div>
          </div>
        </button>
        <nav className="hidden md:flex items-center gap-5 lg:gap-8">
          <Link to="/" className="text-white text-lg hover:text-gray-300">
            Home
          </Link>
          <Link
            to="/terms"
            className="text-white text-lg hover:text-gray-300"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms"
            className="text-white text-lg hover:text-gray-300"
          >
            Terms of Services
          </Link>
          <Link to="/faq" className="text-white text-lg hover:text-gray-300">
            Faq
          </Link>
          <Link
            to="/contact"
            className="text-white text-lg hover:text-gray-300"
          >
            Contact us
          </Link>
        </nav>
        {/* Overlay to Close Menu */}
        <div
          className={`fixed inset-0 bg-black/50 z-[9998] lg:hidden transition-opacity duration-300 ${
            isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
          }`}
          onClick={closeMenuOnOverlayClick}
        />
      </div>
    </header>
  );
};

export default TermsHeader;
