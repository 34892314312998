import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import AboutUsAndContactUsSection from './components/AboutUsAndContactUsSection';
import DownloadSection from './components/DownloadSection';
import FAQSection from './components/FAQSection';
import FooterSection from './components/FooterSection';
import Header from './components/Header';
import HowToInstallSection from './components/HowToInstallSection';
import HowToPlaySection from './components/HowToPlaySection';
import ReviewsSection from './components/ReviewsSection';
import Terms from './components/Terms';
import WhyClawKingSection from './components/WhyClawKingSection';
import WinRealPrizesSection from './components/WinRealPrizesSection';
import TermsHeader from './components/TermsHeader';

const AppContent = () => {
  const location = useLocation();

  // State for managing the TermsHeader menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const closeMenuOnOverlayClick = () => setIsMenuOpen(false);

  // Check if the current route requires TermsHeader (for /terms and /faq pages)
  const isTermsHeaderPage = location.pathname === '/terms' || location.pathname === '/faq' || location.pathname === '/contact';

  return (
    <>
      {/* Render TermsHeader or Header dynamically */}
      {isTermsHeaderPage ? (
        <TermsHeader
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          closeMenuOnOverlayClick={closeMenuOnOverlayClick}
        />
      ) : (
        <Header />
      )}

      {/* Define Routes */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <DownloadSection />
              <HowToInstallSection />
              <HowToPlaySection />
              <ReviewsSection />
              <WinRealPrizesSection />
              <WhyClawKingSection />
              <FAQSection />
              <AboutUsAndContactUsSection />
            </>
          }
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQSection />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/contact" element={<AboutUsAndContactUsSection />} />
      </Routes>

      {/* Footer will be shown on all pages */}
      <FooterSection />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

const Privacy = () => <div>Privacy Policy Page</div>;
const Cancellation = () => <div>Cancellation & Refund Policy Page</div>;
const Delivery = () => <div>Shipping & Delivery Policy Page</div>;

export default App;
